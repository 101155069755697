import { Link } from "react-router-dom";

import './sideMenu.css';

const SideMenu = ({ projectInfo, token }) => {
    return (
        <div className="typesBlock">
            <h1>Типы объектов</h1>
            <div className="typesList">
                { Object.values(projectInfo.types).map((type, index) => (
                    <div key={index}>
                        <Link to={`/dashboard/${type.id}`} state={{ token, type, projectInfo }}>
                            {type.name}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SideMenu;