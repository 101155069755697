import { useState } from 'react';

import SearchPanel from '../searchPanel/SearchPanel';
import Card from '../card/Card';

import { WORKSPACE_ID, PROJECT_ID } from '../constants';

import greyBackground from '../../resources/img/greyBackground.png';

import './searchBar.css';

const SearchBar = ({ projectInfo, token }) => {
    const [searchData, setSearchData] = useState([]);
    const [selectedObject, setSelectedObject] = useState(null);
    const [showCardList, setShowCardList] = useState(false);

    const getObjects = async (filter = '') => {
        const formData = new FormData();
    
        formData.append('sortColumn', 'id');
        formData.append('sortDirection', 0);
        formData.append('filters[name]', filter);

        fetch(`https://invadmin.officescheme.ru/project/${WORKSPACE_ID}/${PROJECT_ID}/API?action=node_list`, {
            method: 'POST',
            headers: {
                'x-ws-common-auth': token
            },
            body: formData
        })
        .then(result => result.json())
        .then(result => {
            const data = [];

            result.items.forEach(object => {
                const fields = {};

                for(let field in projectInfo.types[object.type_uid].fields) {
                    fields[field] = object[field];
                }

                const processedObject = { id: object.id, type: object.type_name, number: object.name, location: projectInfo.tree[object.parent], typeObject: object.type_uid, fields };

                data.push(processedObject);
            });

            setSearchData(data);
        });
    };

    const onSearchInput = (e) => {
        const filter = e.target.value.trim();

        if (filter) {
            getObjects(e.target.value);
        } else {
            setSearchData([]);
        }
    };

    return (
        <>
            { selectedObject ? <Card selectedObject={selectedObject} setSelectedObject={setSelectedObject} projectInfo={projectInfo} /> : ''}
            <div onMouseLeave={() => setShowCardList(false)}>
                { showCardList && searchData.length ? <ul className="searchResult">
                    { searchData.map((object, ind) => {
                        return (
                            <>  
                                <li key={ind} onClick={() => setSelectedObject(object)}>
                                    <img src={greyBackground} alt={"grey background circle"}></img>
                                    <div>
                                        <h1 className="searchResultHeader">{object.number}</h1>
                                        <div className="flexBox">
                                            <p className="title">Тип</p>
                                            <p className="titleValue">{object.type}</p>
                                        </div>
                                        <div className="flexBox">
                                            <p className="title">Расположение</p>
                                            <p className="titleValue">{object.location}</p>
                                        </div>
                                    </div>
                                </li>
                                { ind !== searchData.length - 1 ? <div className="lineSearch"></div> : '' }
                            </>
                        );
                    })}
                </ul> : ''}
                <SearchPanel setShowCardList={setShowCardList} onSearchInput={onSearchInput}/>
            </div>
        </>
    );
};

export default SearchBar;