const getPages = (page, setPage, total, PER_PAGE) => {
    const dots = [];
    const maxDots = Math.ceil(total / PER_PAGE);

    let ellipsisBefore = false;
    let ellipsisAfter = false;

    for(let i = 1; i <= maxDots; i++) {
        if ((i >= page - 1 && i <= page + 1) || i === 1 || i === maxDots) {
            dots.push(<button key={i} className={page === i ? "active" : ""} value={i} onClick={(e) => setPage(+e.target.value)}>{i}</button>);
        } else if (i === page + 2 && page + 1 < maxDots && !ellipsisAfter) {
            dots.push(<button key={i}>...</button>);
            ellipsisAfter = true;
        } else if (page > 3 && !ellipsisBefore) {
            dots.push(<button key={i}>...</button>);
            ellipsisBefore = true;
        }
    }

    return dots;
};

export default getPages;