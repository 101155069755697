import { useState } from 'react';

import TableRow from '../tableRow/TableRow';
import TableHeader from '../tableHeader/TableHeader';
import Card from '../card/Card';

import './table.css';

const Table = ({ data, projectInfo, fields = {} }) => {
    const [selectedObject, setSelectedObject] = useState(null);
    
    const headerData = { id: 'ID', number: 'Инвентарный номер', type: 'Тип объекта', location: 'Размещение', fields };

    return (
        <div className="table">
            { selectedObject ? <Card selectedObject={selectedObject} setSelectedObject={setSelectedObject} projectInfo={projectInfo} /> : ''}
            <TableHeader headerData={headerData} />
            {data.map((object, index) => {
                return (
                    <div className="cardWrapper" onClick={() => setSelectedObject(object)}>
                        <TableRow styleRow="commonRow" key={index} object={object} headerData={headerData} />
                    </div>
                );
            })}
        </div>
    );
  };

export default Table;