import Table from '../table/Table';

import getPages from './../getPages.js';

import { PER_PAGE } from '../constants';

import './list.css';

const List = ({ data, page, setPage, total, projectInfo }) => {
    return (
        <div className="list">
            <h1>Список объектов</h1>
            <div className="tableList">
                <Table data={data} projectInfo={projectInfo}/>
            </div>
            <div className="dots">
                { getPages(page, setPage, total, PER_PAGE) }
            </div>
        </div>
    );
};

export default List;