import { useState } from 'react';

import DropDownList from "../dropDownList/DropDownList";

import './card.css';

const Card = ({ selectedObject, setSelectedObject, projectInfo: { tree, types } }) => {
    const [filter, setFilter] = useState({ typeObject: selectedObject.typeObject });

    const updateFilter = (newVal) => {
        setFilter({ ...filter, ...newVal });
    };

    return (
        <div className={ selectedObject ? 'modalCard' : 'hide' }>
            <div className="card">
                <h1 className="cardHeader">Карточка объекта</h1>
                <div className="selectBoxes">
                    <div className="selectBox">
                        <h2>Введите инвентарный номер</h2>
                        <input disabled className="cardInput" placeholder={selectedObject.number}/>
                    </div>
                    <div className="selectBox">
                        <h2>Выберите тип Объекта</h2>
                        <DropDownList data={types} updateFilter={updateFilter} field={'typeObject'} DEFAULT_VALUE={selectedObject.type} s={true}/>
                    </div>
                    <div className="selectBox">
                        <h2>Размещение</h2>
                        <DropDownList data={Object.values(tree)} updateFilter={updateFilter} field={'location'} DEFAULT_VALUE={selectedObject.location} s={true}/>
                    </div>
                </div>
                <h1 className="cardHeader">Дополнительные поля</h1>
                <div className="additionalFields">
                    { Object.keys(types[filter.typeObject].fields).map(field => {
                        return (
                            <div className="selectBox"> 
                                <h2>{types[filter.typeObject].fields[field]}</h2>
                                <input disabled className="cardInput" placeholder={filter.typeObject === selectedObject.typeObject ? selectedObject.fields[field] : types[filter.typeObject].fields[field]}/>
                            </div>
                        );
                    })}
                </div>
                {/* <div className="description"> 
                    <h2>Описание</h2>
                    <textarea
                        className="cardInput"
                        placeholder={'15.6" Ноутбук DIGMA EVE 15C419 1920x1080, Intel Celeron N4020 1.1 ГГц, RAM 4 ГБ, SSD 128 ГБ, Intel UHD Graphics 600, Windows 10 Home, ES5065EW, темно-серый 15.6"'}
                        maxlength="250"
                    />
                </div> */}
                <div className="buttons">
                    <button className="saveBtn" onClick={() => setSelectedObject(null)}>Сохранить</button>
                    <button className="cancelBtn" onClick={() => setSelectedObject(null)}>Отменить</button>
                </div>
            </div>
        </div>
    );
};

export default Card;