import TableCell from "../tableCell/TableCell";

import './tableRow.css';

const TableRow = ({ object, styleRow, headerData }) => {
    const cells = [];

    for(let val in headerData) {
        if (typeof headerData[val] === 'object') {
            for(let field in headerData[val]) {
                cells.push(<TableCell item={object.fields[field]} />);
            }
        } else {
            cells.push(<TableCell item={object[val]} />);
        }
    }
  
    return (
        <div className={`tableRow ${styleRow}`}>
            {cells}
        </div>
    )
};

export default TableRow;