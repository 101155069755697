import './searchPanel.css';

import photo from '../../resources/img/photo.png';
import search from '../../resources/img/search.png';

const SearchPanel = ({ onSearchInput, setShowCardList }) => {
    return (
        <div className="panel" onMouseEnter={() => setShowCardList(true)}>
            <div className="searchInput">
                <img src={search} alt={"Лупа поиска"}/>
                <input onChange={(e) => onSearchInput(e)} className="searchBar" placeholder="Поиск по всем объектам"/>
            </div>
            <div className="user">
                <img src={photo} alt={"Аватар"}/>
                <div className="userName">Петров Иван Иванович</div>
            </div>
        </div>
    )
};

export default SearchPanel;