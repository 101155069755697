import { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import AuthorizationForm from "../authorizationForm/AuthorizationForm";
import DashBoard from "../dashboard/DashBoard";
import TypeGroup from "../TypeGroup/TypeGroup";

const App = () => {
    const [token, setToken] = useState('');
    const [signIn, setSignIn] = useState(false);

    return (
        <Routes>
            <Route path="/" element={<AuthorizationForm setToken={setToken} setSignIn={setSignIn}/>}/>
            <Route path="/dashboard" element={ signIn ? <DashBoard token={token}/> : <Navigate to="/" replace />}/>
            <Route path="/dashboard/:id" element={signIn ? <TypeGroup /> : <Navigate to="/" replace />}/>
            <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
    );
}

export default App;