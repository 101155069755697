import { useState } from 'react';

import './dropDownList.css';

const DropDownList = ({ data, updateFilter, field, DEFAULT_VALUE, s }) => {
    const [active, setActive] = useState(DEFAULT_VALUE);
    const [show, setShow] = useState(false);

    const typeName = {};

    if (field === 'typeObject') {
        for(let typeObject in data) {
            typeName[data[typeObject].name] = typeObject;
        }

        data = Object.values(data).map(type => type.name)
    }
    
    data = [...new Set([DEFAULT_VALUE, ...data])];

    const onSelectChange = (e) => {
        if (field === 'typeObject') {
            updateFilter({ [field]: typeName[e.target.outerText] });
        } else {
            updateFilter({ [field]: e.target.outerText });
        }

        setActive(e.target.outerText);
        setShow(!show);
    };

    return (
        <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <div className="select">{active}</div>
            <div className={show && !s ? 'showOptions' : 'hideOptions'}>
                {data.map((object, ind) => <div key={ind} onClick={(e) => onSelectChange(e)}>{object}</div>)}
            </div>
        </div>
    );
};

export default DropDownList;