import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import SearchBar from "../searchBar/SearchBar";
import Table from "../table/Table";

import getPages from "../getPages.js";
import DropDownList from "../dropDownList/DropDownList";

import { WORKSPACE_ID, PROJECT_ID, PER_GROUP_PAGE, DEFAULT_GROUP_VALUE } from "../constants";

import './typeGroup.css';

import arrow from '../../resources/img/arrow.png';

const TypeGroup = () => {
    const { state: { projectInfo, token, type } } = useLocation();

    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const updateFilter = (newVal) => {
        setFilter({ ...filter, ...newVal });
    };

    const getObjects = async () => {
        const formData = new FormData();
    
        formData.append('perpage', PER_GROUP_PAGE);
        formData.append('page', page - 1);
        formData.append('sortColumn', 'id');
        formData.append('sortDirection', 0);
        formData.append('filters[type_uid]', type.id);

        fetch(`https://invadmin.officescheme.ru/project/${WORKSPACE_ID}/${PROJECT_ID}/API?action=node_list`, {
            method: 'POST',
            headers: {
                'x-ws-common-auth': token
            },
            body: formData
        })
        .then(result => result.json())
        .then(result => {
            const data = [];

            result.items.forEach(object => {
                const fields = {};

                for(let field in projectInfo.types[object.type_uid].fields) {
                    fields[field] = object[field];
                }

                const processedObject = { id: object.id, type: object.type_name, number: object.name, location: projectInfo.tree[object.parent], typeObject: object.type_uid, fields };

                data.push(processedObject);
            });

            setTotal(result.total);
            setData(data);
        });
    };

    const getFields = () => {
        const fields = [];

        for (let field in type.fields) {
            fields.push((
                <div className="selectProperty">
                    <h2>{type.fields[field]}</h2>
                    <DropDownList data={data.map(object => object.fields[field])} updateFilter={updateFilter} field={field} DEFAULT_VALUE={DEFAULT_GROUP_VALUE}/>
                </div>
            ));
        }

        return fields;
    };

    useEffect(() => {
        getObjects();
    }, [page, projectInfo.tree]);

    return (
        <>
            <SearchBar projectInfo={projectInfo} token={token}/>
            <Link to={'/dashboard'} >
                <img src={arrow} alt="Стрелка назад" className="arrow"></img>
            </Link>
            <div className="groupContainer">
                <h1>{`Список объектов. ${type.name}`}</h1>
                <div className="flexSelect">
                    {getFields()}
                </div>
                <p className="total">{`Количество результатов: ${total}`}</p>
                <div className="tableGroup">
                    <Table data={data} fields={type.fields} projectInfo={projectInfo}/>
                </div>
                <div className="dots">
                    { getPages(page, setPage, total, PER_GROUP_PAGE) }
                </div>
            </div>
        </>
    );
};

export default TypeGroup;